import React from 'react';
import styled from 'styled-components';
import {useI18next, Link, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import Container from 'components/Container';
import {PricingPlan} from 'components/Pricing/PricingPlanInterface';
import {AnchorLink} from 'components/AnchorLink';

import AsSeenOn from 'components/Alternatives/AsSeenOn';
import WhyUse from 'components/Alternatives/WhyUse';
import CheckCells from 'components/Alternatives/CheckCells';
import {
  ComparisonBlock,
  ComparisonTitle,
  PriceCompareTable,
  FeatureCompareTable,
  ComparisonHeadline,
  HeadingRow
} from 'components/Alternatives/ComparisonComponents';
import {
  Features,
  FeatureImage,
  FeatureList,
  FeatureItem,
  FeatureContent
} from 'components/Alternatives/FeatureComponents';
import CallToAction from 'components/Alternatives/CallToAction';
import MoonmailButton from 'components/Button';

import EmailMarketingSoftwareImage from 'images/email-marketing-software.svg';
import MoonmailLogo from 'images/moonmail-logo-full.svg';
import CampaignMonitor from 'images/campaign-monitor.svg';

import FasterEmail from 'images/faster-email.svg';
import Cleaning from 'images/cleaning.svg';
import UnlimitedSubs from 'images/unlimited-subs.svg';
import SimplePricing from 'images/simple-pricing.svg';
import PersonalizationTags from 'images/personalization-tags.svg';
import MultilingualChat from 'images/multilingual-chat.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 400px;
  margin: 30px auto;
  font-weight: 300;
`;

const Page: React.FC = () => {
  const {t} = useI18next();
  const alternative = 'Campaign Monitor';
  return (
    <>
      <Helmet title={t('{{alternative}} alternative', {alternative})}>
        <meta
          name="description"
          content={t(
            `Looking for a {{alternative}} Alternative? MoonMail is your choice if you're looking for the email marketing platform for the professional marketer. Click here to learn more!`,
            {alternative}
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={EmailMarketingSoftwareImage}
                alt={t('Email marketing software')}
                title={t('OmniChannel Communication Platform')}
                width={296}
                height={201}
              />
              <h1>{t('Looking for a {{alternative}} Alternative?', {alternative})}</h1>
              <IndexSectionSummary>
                {t(
                  'MoonMail is your choice if you’re looking for the email marketing platform for the PROS.'
                )}
              </IndexSectionSummary>
              <p>
                <Button
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to fly to the moon')}>
                  {t('Switch to MoonMail now')}
                </Button>
              </p>
            </IndexSectionContainer>
          </IndexSection>
          <AsSeenOn />
        </Stars>
        <section>
          <Container>
            <WhyUse name="{{alternative}}">
              <Trans>
                Email marketing is still one of the most-used strategies in digital marketing. With
                so many tools out there, it’s hard to determine which one fits your needs. Campaign
                Monitor is targeting more medium companies and the current price schema is not
                affordable for small companies anymore.
              </Trans>
            </WhyUse>
          </Container>
          <ComparisonTitle>{t('Pricing Comparison per One Month')}</ComparisonTitle>
          <ComparisonBlock>
            <PriceCompareTable>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <img
                      src={MoonmailLogo}
                      alt={t('MoonMail vs {{alternative}}', {alternative})}
                      title={t('MoonMail vs {{alternative}}', {alternative})}
                    />
                  </th>
                  <th>
                    <img
                      src={CampaignMonitor}
                      alt={t('{{alternative}} alternative', {alternative})}
                      title={t('{{alternative}} alternative', {alternative})}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('Send 1 campaign to 5,000 subscribers')}</td>
                  <td>$27.45</td>
                  <td>$49</td>
                </tr>
                <tr>
                  <td>{t('Send 1 campaign to 25,000 subscribers')}</td>
                  <td>$57.25</td>
                  <td>$199</td>
                </tr>
                <tr>
                  <td>{t('Send 1 campaign to 50,000 subscribers')}</td>
                  <td>$124.50</td>
                  <td>$329</td>
                </tr>
              </tbody>
            </PriceCompareTable>
          </ComparisonBlock>
          <ComparisonHeadline>
            <Trans>
              <p>
                MoonMail has 3 simple paid plans to fit different needs depending on the number of
                subscribers: 25,000; 70,000 or unlimited. All plans include full access to features
                and extensions. With {{alternative}}, to get access to all features, you need to
                select a bigger plan that costs 3 times more than the basic.
              </p>
              <p>
                MoonMail doesn’t charge you for small difference on subscribers and limitations on
                the email quota: you pay for what you send. MoonMail’s Starter plan is fixed at
                $19.99 + sending fee starting at $1.49 for every 1,000 emails sent, this fee is
                based on your reputation and you can go as low as $0.05 if you have an excellent
                reputation.
              </p>
            </Trans>
          </ComparisonHeadline>
          <ComparisonTitle>{t('Feature Summary')}</ComparisonTitle>
          <ComparisonBlock>
            <FeatureCompareTable>
              <tr>
                <th></th>
                <th>
                  <img
                    src={MoonmailLogo}
                    alt={t('MoonMail vs {{alternative}}', {alternative})}
                    title={t('MoonMail vs {{alternative}}', {alternative})}
                  />
                </th>
                <th>
                  <img
                    src={CampaignMonitor}
                    alt={t('{{alternative}} alternative', {alternative})}
                    title={t('{{alternative}} alternative', {alternative})}
                  />
                </th>
              </tr>
              <tbody>
                <tr>
                  <td>{t('Unlimited Subscribers')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Unlimited emails/month')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Drag and drop editor')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Automations')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('List Segmentation')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Unlimited Custom Personalization tags')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Liquid markup syntax')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Responsive emails')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Click map')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Real-time statistics')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Double opt-in')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td> {t('Webforms')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Steps to sign up')}</td>
                  <td>2</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>{t('Multilingual Interface')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Mulitilingual Live Chat Support')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Free Support to all Users')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Fully GDPR compliant')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>
                    <Link to="/email-marketing-affiliate-program/">
                      {t('Make money with the platform')}
                    </Link>
                  </td>
                  <CheckCells values={[true, false]} />
                </tr>
                <HeadingRow>
                  <td>{t('For the techy ones...')}</td>
                  <td></td>
                  <td></td>
                </HeadingRow>
                <tr>
                  <td>{t('Choose double opt in or not in your web forms')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Attach files*')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('RSS to email*')}</td>
                  <CheckCells values={[true, true]} />
                </tr>
                <tr>
                  <td>{t('Free schema.org markup assessment')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Fully Open Source Project')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>
                    <Trans>
                      Deploy the whole software into your own Amazon
                      <br />
                      Web Services Account
                    </Trans>
                  </td>
                  <CheckCells values={[true, false]} />
                </tr>
                <tr>
                  <td>{t('Use your own dedicated IP')}</td>
                  <CheckCells values={[true, false]} />
                </tr>
              </tbody>
            </FeatureCompareTable>
          </ComparisonBlock>
          <CallToAction>
            <ComparisonTitle>
              {t('Switch now from {{alternative}} to MoonMail!', {alternative})}
            </ComparisonTitle>
            <MoonmailButton href={links.signUp} title={t('Try MoonMail for Free')}>
              {t('Signup for Free')}
            </MoonmailButton>
          </CallToAction>
        </section>
        <Features>
          <Container>
            <FeatureList>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={UnlimitedSubs}
                    alt={t('The best {{alternative}} alternative has Unlimited subscribers', {
                      alternative
                    })}
                    title={t('The best {{alternative}} alternative has Unlimited subscribers', {
                      alternative
                    })}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Unlimited Subscribers')}</ComparisonTitle>
                  <p>
                    <Trans>
                      You can store as many subscribers’ emails as you want. While {{alternative}}
                      automatically upgrades you to a higher billing plan if you exceed your free
                      subscriber limits, we will never automatically upgrade your billing plan.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={SimplePricing}
                    alt={t('The best {{alternative}} Alternative has simple pricing', {
                      alternative
                    })}
                    title={t('The best {{alternative}} Alternative has simple pricing', {
                      alternative
                    })}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>
                    {t('Simple and flexible pricing: Pay for what you send')}
                  </ComparisonTitle>
                  <p>
                    <Trans>
                      Compared to {{alternative}}, MoonMail doesn’t tie you to strict limitations on
                      how many subscribers you can have. Simply put, with MoonMail you can have as
                      many subscribers as you want and your subscription will be the same.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={FasterEmail}
                    alt={t('The best {{alternative}} alternative is fast', {alternative})}
                    title={t('The best {{alternative}} alternative is fast', {alternative})}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Sending email faster than ever')}</ComparisonTitle>
                  <p>
                    <Trans>
                      For signup, you need to register with a{' '}
                      <AnchorLink
                        href="https://support-legacy.moonmail.io/accounts-and-validation/accounts/can-i-sign-in-without-google-account"
                        target="_blank"
                        external>
                        Google account.
                      </AnchorLink>{' '}
                      With one click, you verify your phone and fill your company information, and
                      that’s it. After sign-up with MoonMail, just import your list of subscribers,
                      create a campaign, and send it. Skip all the unnecessary questions and start
                      sending emails without verifying your sender email address.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={PersonalizationTags}
                    alt={t('The best {{alternative}} alternative uses liquid syntax', {
                      alternative
                    })}
                    title={t('The best {{alternative}} alternative uses liquid syntax', {
                      alternative
                    })}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Personalization tags and liquid syntax')}</ComparisonTitle>
                  <p>
                    <Trans>
                      Both MoonMail and {{alternative}} have personalization tags. However, with
                      MoonMail you can both select existing tags and create you own{' '}
                      <AnchorLink
                        href="https://support-legacy.moonmail.io/the-platformFeatureItemsts-and-subscribers/how-do-i-add-custom-fields-to-my-lists"
                        target="_blank"
                        external>
                        personalization tags
                      </AnchorLink>
                      , without limitation, to match your needs.
                    </Trans>
                  </p>
                  <p>
                    <Trans>
                      We use Liquid syntax that helps you to create complex interactions, such as
                      dynamic content, by adding simple rules.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
              <FeatureItem>
                <FeatureImage>
                  <img
                    src={MultilingualChat}
                    alt={t(
                      'The best {{alternative}} Alternative has Multilingual Live Chat Support',
                      {alternative}
                    )}
                    title={t(
                      'The best {{alternative}} Alternative has Multilingual Live Chat Support',
                      {alternative}
                    )}
                  />
                </FeatureImage>
                <FeatureContent>
                  <ComparisonTitle>{t('Multilingual Live Chat Support')}</ComparisonTitle>
                  <p>
                    <Trans>
                      At MoonMail, we have a multilingual team available to support you if you need
                      help or just want to say hi. {{alternative}}, in comparison, sends you through
                      forums, forms, and many questions before ever having a personal conversation.
                      You don’t need to have an emergency to contact the MoonMail team, which
                      supports (today) 10 languages.
                    </Trans>
                  </p>
                </FeatureContent>
              </FeatureItem>
            </FeatureList>
            <CallToAction>
              <ComparisonTitle>
                {t(
                  'Willing to have a dedicated Account Manager help you with your {{alternative}} to MoonMail migration?',
                  {alternative}
                )}
              </ComparisonTitle>
              <MoonmailButton href={links.signUp} title={t('Migrate now for Free')}>
                {t('Migrate now for Free')}
              </MoonmailButton>
            </CallToAction>
          </Container>
        </Features>
      </div>
    </>
  );
};

export default Page;
